import React from 'react';

import { ModuleWrapper } from '../moduleWrapper';
import { SanityImage } from '@components/sanity/sanityImage';

const SimplePageBanner = ({ config, image, title }) => {
  return (
    <ModuleWrapper {...config}>
      <div className="bg-black relative">
        <div className="aspect-w-16 aspect-h-16 md:aspect-h-7 lg:aspect-h-6">
          <SanityImage image={image} className="absolute inset-0 object-cover opacity-50" />
        </div>
        <h1 className="text-white text-3xl font-medium md:text-7xl text-center absolute top-1/2 transform -translate-y-1/2 w-full">
          {title}
        </h1>
      </div>
    </ModuleWrapper>
  );
};

export default SimplePageBanner;
